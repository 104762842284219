export const testimonialsStrings = {
    en:{
        testimonialsTitle1:"Testimonials",
        testimonialsTitle2:"People speak",
    },
    pl: {
        testimonialsTitle1:"Referencje",
        testimonialsTitle2:"Ludzie mówią",
    },
};

export default testimonialsStrings;