export const commonStrings = {
    en:{
        languageSwitcherPL: "Polski",
        languageSwitcherEN: "English",
    },
    pl: {
        languageSwitcherPL: "Polski",
        languageSwitcherEN: "English",
    },
};

export default commonStrings;