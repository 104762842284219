import React, {useContext} from "react";
import {LangContext} from "../lang/LangContext";

const LanguageSwitcher = () => {
    const langContext = useContext(LangContext);

    const availableLanguages = langContext.strings.getAvailableLanguages();
    const currentLanguage = langContext.strings.getLanguage();

    return <>
        {availableLanguages.map((option) => {
            if (currentLanguage !== option) {
                return <button
                    className="btn btn-outline-primary rounded-pill shadow-none smooth-scroll mt-2"
                    onClick={(e) => {
                        langContext.setLang(option);
                    }} key={`language-${option}`}>
                    {langContext.strings.getString(`languageSwitcher${option.toUpperCase()}`)}
                </button>;
            }
        })};
    </>;

}

export default LanguageSwitcher;