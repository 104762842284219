export const aboutStrings = {
    en:{
        aboutMeTitle1:"About me",
        aboutMeTitle2:"Knowing me more",
        iAm: "I'm",
        aboutMeContent1:"Experienced analyst, designer, and programmer of IT systems.\n" +
            "I have over 20 years in the IT industry. \n" +
            "\n" +
            "For many years I worked as an analyst, designer and programmer of custom intranet systems. I have many systems supporting the work of companies (CRM systems, order brokerage systems, training management, e-commerce).\n" +
            "\n" +
            "Currently I work as a REST microservice programmer using the JVM environment (Kotlin/Java).",
        yearsExperience: "Years Experiance",
        happyCustomers: "Happy customers",
        projectsDone: "Projects",
    },
    pl: {
        aboutMeTitle1:"O mnie",
        aboutMeTitle2:"Poznajmy się",
        iAm: "Jestem",
        aboutMeContent1:"Doświadczony analityk, projektant i programista systemów IT.\n" +
            "Posiadam ponad 20 lat doświadczenia w dziedzinie informatyki.\n" +
            "\n" +
            "Przez wiele lat pracowałem jako analityk, projektant i programista dedykowanych systemów intranetowych. W swoim dorobku posiadam wiele systemów wspierających prace firm (systemy CRMs, systemy pośrednictwa zleceń, zarządzanie szkoleniami, e-commerce).\n" +
            "\n" +
            "Aktualnie pracuję jako programista mikrousług REST przy wykorzystaniu środowiska JVM (Kotlin/Java). \n",
        yearsExperience: "Lata doświadczenia",
        happyCustomers: "Zadowoleni klienci",
        projectsDone: "Projekty",
    },
};

export default aboutStrings;