export const headerStrings = {
    en:{
        headerHome:"Home",
        headerAboutMe:"About Me",
        headerWhatIDo:"What I Do",
        headerResume:"Resume",
        headerPortfolio:"Portfolio",
        headerTestimonial:"Testimonial",
        headerContact:"Contact",
    },
    pl: {
        headerHome:"Start",
        headerAboutMe:"O mnie",
        headerWhatIDo:"Czym się zajmuję",
        headerResume:"Doświadczenie",
        headerPortfolio:"Portfolio",
        headerTestimonial:"Opinie",
        headerContact:"Kontakt",
    },
};

export default headerStrings;