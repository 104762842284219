export const contactStrings = {
    en:{
        contactTitle1:"Contact",
        contactTitle2:"Get in Touch",
        contactPhone: "Phone",
        contactSendMeANote: "Send me a note",
        contactPlaceholderName: "Name",
        contactPlaceholderEmail: "Email",
        contactPlaceholderContent: "Tell me about your needs...",
        contactButtonTitle: "Wyślij wiadomość",
        contactSending: "Sending message...",
        contactSendingSuccess: "Message sent successfully! Wait for quick reply ;)",
        contactSendingError: "Something went wrong. The message ",
    },
    pl: {
        contactTitle1:"Kontakt",
        contactTitle2:"Złapmy się",
        contactPhone: "Telefon",
        contactSendMeANote: "Wyślij mi wiadomość",
        contactPlaceholderName: "Imię i nazwisko",
        contactPlaceholderEmail: "E-mail",
        contactPlaceholderContent: "Napisz czego potrzebujesz...",
        contactButtonTitle: "Wyślij wiadomość",
        contactSending: "Wysyłanie wiadomości...",
        contactSendingSuccess: "Wiadomość została wysłana! Oczekuj szybkiej odpowiedzi ;)",
        contactSendingError: "Niestety nie udało się wysłać wiadomości.",
    },
};

export default contactStrings;