import React from "react";
import useStrings from "../hooks/useStrings";

const LegalNotice = ({ darkTheme }) => {
  const langStrings = useStrings();

  return (
    <div
      id="disclaimer"
      className="modal fade"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg modal-dialog-centered"
        role="document"
      >
        <div
            className={
                "modal-content " + (darkTheme ? "bg-dark-2 text-light" : "")
            }
        >
          <div className="modal-header">
            <h5 className={"modal-title " + (darkTheme ? "text-white" : "")}>
              {langStrings.legalNoticeTitle}
            </h5>
            <button
                type="button"
                className={"btn-close " + (darkTheme ? "btn-close-white" : "")}
                data-bs-dismiss="modal"
                aria-label="Close"
            />
          </div>
          <div className="modal-body p-4">
            <p className={"fw-bold"}>{langStrings.legalNoticeTitle1}</p>
            <p>
              {langStrings.legalNoticeContent1}
            </p>
            <p className={"fw-bold"}>{langStrings.legalNoticeTitle2}</p>
            <p>
              {langStrings.legalNoticeContent2}
            </p>
            <p className={"fw-bold"}>{langStrings.legalNoticeTitle3}</p>
            <p>
              {langStrings.legalNoticeContent3}
            </p>
            <p className={"fw-bold"}>{langStrings.legalNoticeTitle4}</p>
            <p>
              {langStrings.legalNoticeContent4}
            </p>
            <p className={"fw-bold"}>{langStrings.legalNoticeTitle5}</p>
            <p>
              {langStrings.legalNoticeContent5}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LegalNotice;
