import LocalizedStrings from "react-localization";
import commonStrings from "./common";
import aboutStrings from "./about";
import homeStrings from "./home";
import headerStrings from "./header";
import resumeStrings from "./resume";
import contactStrings from "./contact";
import serviceStrings from "./services";
import legalNoticeStrings from "./disclaimer";
import termsAndConditionsStrings from "./terms-and-conditions";
import testimonialsStrings from "./testimonials";

let joinedStrings = {
    en: {},
    pl: {},
};

const addStrings = (strings) => {
    joinedStrings = {
        en: {...joinedStrings.en, ...strings.en},
        pl: {...joinedStrings.pl, ...strings.pl},
    }
};

addStrings(headerStrings);
addStrings(homeStrings);
addStrings(aboutStrings);
addStrings(commonStrings);
addStrings(resumeStrings);
addStrings(resumeStrings);
addStrings(contactStrings);
addStrings(serviceStrings);
addStrings(legalNoticeStrings);
addStrings(termsAndConditionsStrings);
addStrings(testimonialsStrings);

export const strings = new LocalizedStrings(joinedStrings);
