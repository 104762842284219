export const legalNoticeStrings = {
    en:{
        legalNoticeTitle:"Legal Notice",
        legalNoticeTitle1:"Disclaimer",
        legalNoticeContent1:"Disclaimer",
    },
    pl: {
        legalNoticeTitle:"Nota prawna",
        legalNoticeTitle1:"Informacje ogólne",
        legalNoticeContent1:"Niniejsza strona internetowa jest własnością \"APLINSoft - Wojciech Nowogrodzki\" z siedzibą w Markach. Kontakt z właścicielem strony możliwy jest pod adresem e-mail: wojciech!nowogrodzki.pl (w miejsce ! należy wstawić @).",
        legalNoticeTitle2:"Prawa autorskie i znaki towarowe",
        legalNoticeContent2:"Wszystkie materiały dostępne na stronie, w tym teksty, grafiki, zdjęcia, logotypy oraz inne elementy wizualne, są chronione prawem autorskim i należą do [Nazwa firmy] lub są wykorzystywane na podstawie odpowiednich licencji. Kopiowanie, rozpowszechnianie lub wykorzystywanie tych materiałów w jakikolwiek sposób bez zgody właściciela jest zabronione.",
        legalNoticeTitle3:"Zastrzeżenie odpowiedzialności",
        legalNoticeContent3:"Informacje zawarte na stronie mają charakter informacyjny i nie stanowią oferty handlowej w rozumieniu przepisów Kodeksu cywilnego. \"APLINSoft - Wojciech Nowogrodzki\" nie ponosi odpowiedzialności za błędy w treściach, ani za decyzje podjęte na ich podstawie.",
        legalNoticeTitle4:"Polityka prywatności i pliki cookies",
        legalNoticeContent4:"Korzystanie ze strony może wiązać się z zapisywaniem plików cookies na urządzeniu użytkownika w celu poprawy funkcjonalności serwisu. Strona nie przetwarza i nie przechowuje żadnych danych pozwalających na identyfikację użytkownika.",
        legalNoticeTitle5:"Zmiany w notach prawnych",
        legalNoticeContent5:"\"APLINSoft - Wojciech Nowogrodzki\" zastrzega sobie prawo do wprowadzania zmian w niniejszej nocie prawnej. Użytkownicy są zobowiązani do regularnego zapoznawania się z jej treścią.",
    },
};

export default legalNoticeStrings;















