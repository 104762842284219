export const resumeStrings = {
    en:{
        resumeTitle:"Resume",
        resumeSubtitle:"Summary",
        resumeEducation:"Education",
        resumeExperience:"Experience",
        resumeBachelorDegree:"Bachelor Degree",
        resumeMasterDegree:"Master Degree",
        resumeSchool1:"Polish-Japanese Academy of Information Technology",
        resumeMasterDescription: "Diploma: Software configuration management system (PowerBuilder with Sybase database)",
        resumeBachelorDescription: "Diploma: SNMP protocol client (Java)",
        resumeProgrammingSkills: "Programming skills",
        resumeOtherSkills: "Other skills",
        resumeDatabasesSkills: "Databases",
        resumeDevOpsSkills: "DevOps",
    },
    pl: {
        resumeTitle:"Resume",
        resumeSubtitle:"Podsumowanie",
        resumeEducation:"Wykształcenie",
        resumeExperience:"Doświadczenie",
        resumeBachelorDegree:"Licencjat",
        resumeMasterDegree:"Magister",
        resumeSchool1:"Polsko-Japońska Akademia Technik Komputerowych",
        resumeMasterDescription: "Dyplom: System zarządzania wersjami (PowerBuilder + baza danych Sybase)",
        resumeBachelorDescription: "Dyplom: Klient protokołu SNMP (Java)",
        resumeProgrammingSkills: "Umiejętności programistyczne",
        resumeOtherSkills: "Pozostałe umiejętności",
        resumeDatabasesSkills: "Bazy danych",
        resumeDevOpsSkills: "DevOps",
    },
};

export default resumeStrings;