import React from "react";
import useStrings from "../hooks/useStrings";

const AboutUs = ({ classicHeader, darkTheme }) => {
  const strings = useStrings();

  return (
    <section id="about" className={"section " + (darkTheme ? "bg-dark-1" : "")}>
      <div className={"container " + (classicHeader ? "" : "px-lg-5")}>
        {/* Heading */}
        <div className="position-relative d-flex text-center mb-5">
          <h2
            className={
              "text-24  text-uppercase fw-600 w-100 mb-0 " +
              (darkTheme ? "text-muted opacity-1" : "text-light opacity-4")
            }
          >
            {strings.aboutMeTitle1}
          </h2>
          <p
            className={
              "text-9 text-dark fw-600 position-absolute w-100 align-self-center lh-base mb-0 " +
              (darkTheme ? "text-white" : "text-dark")
            }
          >
            {strings.aboutMeTitle2}
            <span className="heading-separator-line border-bottom border-3 border-primary d-block mx-auto" />
          </p>
        </div>
        {/* Heading end*/}
        <div className="row gy-5">
          {/* About me content start */}
          <div className="text-center text-lg-start">
            <h2
              className={
                "text-7 fw-600 mb-3 " + (darkTheme ? "text-white" : "")
              }
            >
              {strings.iAm} <span className="text-primary">Wojciech Nowogrodzki</span>,
            </h2>
            <p className={darkTheme ? "text-white-50" : ""}>
              {strings.aboutMeContent1.split("\n").map((str, index) => <p key={index}>{str}</p>) }
            </p>
            {/*<p className={darkTheme ? "text-white-50" : ""}>*/}
            {/*  {strings.aboutMeContent2.split("\n").map(str => <p>{str}</p>) }*/}
            {/*</p>*/}
          </div>
          {/* About me content end */}
          {/* about me personal detials start */}
          {/*<div className="col-lg-5 col-xl-4">*/}
          {/*  <div className="ps-lg-4">*/}
          {/*    <ul*/}
          {/*      className={*/}
          {/*        "list-style-2 " +*/}
          {/*        (darkTheme ? "list-style-light text-light" : "")*/}
          {/*      }*/}
          {/*    >*/}
          {/*      <li>*/}
          {/*        <span className="fw-600 me-2">Name:</span>Simone Olivia*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span className="fw-600 me-2">Email:</span>*/}
          {/*        <a href="mailto:chat@simone.com">chat@simone.com</a>*/}
          {/*      </li>*/}
          {/*      <li>*/}
          {/*        <span className="fw-600 me-2">Age:</span>28*/}
          {/*      </li>*/}
          {/*      <li className="border-0">*/}
          {/*        <span className="fw-600 me-2">From:</span>Los Angeles,*/}
          {/*        California*/}
          {/*      </li>*/}
          {/*    </ul>*/}
          {/*    <a*/}
          {/*      href={resumeFile}*/}
          {/*      download*/}
          {/*      className="btn btn-primary rounded-pill"*/}
          {/*    >*/}
          {/*      Download CV*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/* about me personal details end */}
        </div>
        {/* projects rewards counting start */}
        <div
          className={
            "brands-grid separator-border mt-5 " +
            (darkTheme ? "separator-border-light" : "")
          }
        >
          <div className="row">
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>20</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {strings.yearsExperience}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>10</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {strings.happyCustomers}
                </p>
              </div>
            </div>
            <div className="col-6 col-md-4">
              <div className="featured-box text-center">
                <h4
                  className={
                    "text-12  mb-0 " +
                    (darkTheme ? "text-white-50" : "text-muted")
                  }
                >
                  <span>50</span>+
                </h4>
                <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>
                  {strings.projectsDone}
                </p>
              </div>
            </div>
            {/*<div className="col-6 col-md-3">*/}
            {/*  <div className="featured-box text-center">*/}
            {/*    <h4*/}
            {/*      className={*/}
            {/*        "text-12  mb-0 " +*/}
            {/*        (darkTheme ? "text-white-50" : "text-muted")*/}
            {/*      }*/}
            {/*    >*/}
            {/*      <span>38</span>*/}
            {/*    </h4>*/}
            {/*    <p className={"mb-0 " + (darkTheme ? "text-light" : "")}>*/}
            {/*      Get Awards*/}
            {/*    </p>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        {/* projects rewards counting end */}
      </div>
    </section>
  );
};

export default AboutUs;
