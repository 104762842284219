export const serviceStrings = {
    en:{
        serviceTitle1:"Services",
        serviceTitle2:"What I do",
        serviceAnalysisTitle: "Requirements analysis",
        serviceAnalysisText: "You say what you need. I present how I can solve it in the most optimal way. Sometimes it will be the implementation of existing software, sometimes the creation of a new system.",
        serviceDesignTitle: "Database design",
        serviceDesignText: "We determine what data is to be collected (quantity, type, structure). Then I design a database that stores the data in the most optimal and consistent way.",
        serviceDevelopmentTitle: "Development",
        serviceDevelopmentText: "Implementing a solution is the main part of every project. When creating software, I think first and foremost about the client's needs. The entire project is run on a test environment to check if everything works as it should.",
        serviceImplementationTitle: "Implementation",
        serviceImplementationText: "Every program or system must be properly launched and configured so that all of its components work well together. The first days and weeks of a new solution are especially important.",
        serviceMonitoringTitle: "Monitoring",
        serviceMonitoringText: "It is good to monitor the system while it is running. Monitoring allows you to track how all systems are working and react quickly in case of any problems.",
        serviceSupportTitle: "Support",
        serviceSupportText: "If something isn't working, you need to react quickly. Such reports or monitoring alerts are treated with the highest priority.",
        serviceImprovementsTitle: "Improvements and changes",
        serviceImprovementsText: "Sometimes you need to improve or change something to adapt the system to new requirements or changing market or legal conditions.",
    },
    pl: {
        serviceTitle1:"Usługi",
        serviceTitle2:"Co robię",
        serviceAnalysisTitle: "Analiza wymagań",
        serviceAnalysisText: "Ty mówisz, czego potrzebujesz. Ja przedstawiam jak mogę rozwiązać w najbardziej optymalny sposób. Czasem to będzie wdrożenie istniejącego oprogramowania, czasem stworzenie nowego systemu.",
        serviceDesignTitle: "Projektowanie baz danych",
        serviceDesignText: "Ustalamy jakie dane mają być gromadzone (ilość, rodzaj, struktura). Następnie projektuję bazę danych, która przechowuje dane w najbardziej optymalny i spójny sposób.",
        serviceDevelopmentTitle: "Tworzenie",
        serviceDevelopmentText: "Realizacja rozwiązania to główna część każdego projektu. Tworząc oprogramowanie przede wszystkim myślę o potrzebach klienta. Cały projekt uruchamiany jest na środowisku testowym, żeby sprawdzić czy wszystko działa tak jak należy.",
        serviceImplementationTitle: "Wdrożenie",
        serviceImplementationText: "Każdy program, czy system musi być poprawnie uruchomiony i skonfigurowany tak, aby wszystkie jego komponenty dobrze ze sobą współpracowały. Szczególnie ważne są pierwsze dni i tygodnie działania nowego rozwiązania.",
        serviceMonitoringTitle: "Monitoring",
        serviceMonitoringText: "Podczas działania systemu dobrze jest go monitorować. Monitoring pozwala śledzić jak działają wszystkie systemy i szybko reagować w przypadku ewentualnych problemów.",
        serviceSupportTitle: "Wsparcie",
        serviceSupportText: "Jak coś nie działa, trzeba szybko reagować. Tego typu zgłoszenia lub alarmy monitoringu są traktowane z najwyższym priorytetem.",
        serviceImprovementsTitle: "Rozwój i modyfikacja",
        serviceImprovementsText: "Czasem trzeba coś usprawnić lub zmienić, aby dopasować system do nowych wymagań, zmieniających się warunków rynkowych lub prawnych.",
    },
};

export default serviceStrings;