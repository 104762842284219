export const homeStrings = {
    en:{
        // homeWelcome:"Welcome, I'm...",
        // homeIm1:"Wojciech Nowogrodzki",
        homeIm2:"Web solutions architect",
        homeIm3:"Custom applications designer",
        homeIm4:"IT consultant",
        homeIm5:"Webservices programmer",
        homeHireMe:"Knowing me more",
    },
    pl: {
        // homeWelcome:"Dzień dobry, jestem...",
        // homeIm1:"Wojciech Nowogrodzki",
        homeIm2:"Architekt rozwiązań internetowych",
        homeIm3:"Projektant aplikacji dedykowanych",
        homeIm4:"Konsultant IT",
        homeIm5:"Programista Webservices",
        homeHireMe:"Poznajmy się",
    },
};

export default homeStrings;