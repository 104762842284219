import {LangContext} from "./LangContext";
import {useState} from "react";
import {strings} from "../config/strings/strings";

export const LangContextProvider = ({children}) => {
    const [lang, setLang] = useState(strings.getInterfaceLanguage());

    const context = {
        lang: lang,
        setLang: setLang,
        strings: strings,
    }

    strings.setLanguage(lang);

    return <LangContext.Provider value={context}>{children}</LangContext.Provider>;
}